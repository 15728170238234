import { BasicItem, BasicPivotItem } from 'src/worker/pivotWorker.types';
import {
  CompanionDataItem,
  getId,
  GridMeasureDefn,
  RowData,
} from 'src/pages/AssortmentBuild/FlowSheet/FlowSheet.types';
import { GridItem } from 'src/utils/Component/AgGrid/AgDataFormat';
import { CompanionDataLookup } from 'src/utils/Component/ListView';
import { simpleByField } from 'src/utils/Pivot/Sort';
import { IRowNode } from '@ag-grid-community/core';
import { find, isNil, isString, isUndefined, mapKeys, mapValues } from 'lodash';
import { STYLE_ID, STYLE_DESCRIPTION, CC_COLOR } from 'src/utils/Domain/Constants';
import Renderer from 'src/utils/Domain/Renderer';
import { SortByDirection } from 'src/components/Subheader/Subheader.types';

export function companionDataParse(
  uniqueItems: GridItem[],
  companionDataLookup: CompanionDataLookup,
  sortDirection: SortByDirection,
  sortField?: string,
  levelField?: string
): CompanionDataItem[] {
  let companionData = uniqueItems;

  if (sortField) {
    companionData = simpleByField(companionData, sortField, sortDirection);
  }

  if (!companionData) {
    return [];
  }

  if (levelField) {
    if (levelField === 'member:subclass') {
      const groupedCompanionData: GridItem[] = [];
      companionData.forEach((style) => {
        const id = `${levelField}:id`;
        const item = { id: style[id] };
        const existingObj = find(groupedCompanionData, item);
        if (existingObj) {
          (existingObj as BasicPivotItem).children.push(style);
        } else {
          groupedCompanionData.push({
            id: style[`${levelField}:id`],
            description: style[`${levelField}:name`],
            name: style[`${levelField}:name`],
            '@path': '',
            image: style['attribute:img:id'],
            attributevaluerank: 0,
            [`${levelField}:id`]: style[`${levelField}:id`],
            [`${levelField}:name`]: style[`${levelField}:id`],
            [`${levelField}:description`]: style[`${levelField}:description`],
            'attribute:img:id': style['attribute:img:id'],
            'attribute:img:name': style['attribute:img:name'],
            children: [],
          });
        }
      });
      companionData = groupedCompanionData;

      companionDataLookup = {
        bodyId: `${levelField}:description`,
        displayTitle: `${levelField}:name`,
        imageUrlId: 'attribute:img:id',
        starsId: '0',
        titleId: `${levelField}:id`,
      };
    }
  }

  return companionData.map((datum) => ({
    title: companionDataLookup.displayTitle ? datum[companionDataLookup.displayTitle] : undefined,
    id: datum[companionDataLookup.titleId],
    name: datum[companionDataLookup.bodyId],
    stars: parseInt(datum[companionDataLookup.starsId || ''], 10) || 0,
    imageUri: datum[companionDataLookup.imageUrlId],
  }));
}

export function convertPivotItemsToRowData(
  flowItems: BasicPivotItem[],
  viewDefns: GridMeasureDefn[],
  requiredKeys: string[] = [
    'weekadjslsu',
    'ispublishable',
    'dc_publish',
    'islocked',
    'dc_sysvrp',
    'dc_uservrp',
    'po_vrp',
  ],
  primaryField = 'product',
  secondaryField = 'time',
  extraField: string = STYLE_ID,
  nameMask?: string,
  displayTitleParams?: {
    [key: string]: string;
  }
): RowData[] {
  const items = {};
  const rowData: RowData[] = [];

  flowItems.forEach((item) => {
    let datumItem = items[item[primaryField]];
    if (!items[item[primaryField]]) {
      let datumName = '';

      // switch to preserve the old behavior of desc: color
      // which supports requesting values with a colon character in the key
      if (!nameMask) {
        const styleDescription = !isNil(item[STYLE_DESCRIPTION]) ? item[STYLE_DESCRIPTION] : '';
        const ccColor = !isNil(item[CC_COLOR]) ? item[CC_COLOR] : '';
        datumName = `${styleDescription}: ${ccColor}`;
      } else {
        if (displayTitleParams != null) {
          const updateItem = mapValues(displayTitleParams, (val, _key) => item[val]) as BasicItem
          datumName = Renderer.template(updateItem, nameMask);
        } else {
          datumName = Renderer.template(item, nameMask);
        }
      }

      datumItem = items[item[primaryField]] = {
        id: item[primaryField],
        path: [item[primaryField]],
        groupId: item[primaryField],
        name: datumName,
        hidden: false,
        extraData: {},
        additionalId: item[extraField] || null,
      };
      rowData.push(datumItem);
      const requiredMeasures = {};
      requiredKeys.forEach((key) => (requiredMeasures[key] = 1));

      viewDefns.forEach((measure: GridMeasureDefn) => {
        const measureId = measure.dataIndex;
        let measureItem = datumItem[measureId];
        if (!measureItem) {
          measureItem = datumItem.extraData[measureId] = {
            id: getId(item[primaryField], measureId),
            path: [item[primaryField], measureId],
            groupId: item[primaryField],
            measureId: measureId,
            name: measure.text || measureId,
            extraData: {},
            editable: !!measure.editable,
            editableByCalc: measure.editableByCalc,
            ignoreLock: measure.ignoreLock,
            ignorePublished: measure.ignorePublished,
            renderer: measure.renderer,
            classes: measure.classes || [],
            hidden: !isUndefined(measure.visible) ? !measure.visible : !!measure['hidden'],
            hideFromConfigurator: measure.hideFromConfigurator,
            inputType: measure.inputType,
            inputParams: {
              ...measure.inputParams,
            },
            calculation: measure.calculation,
            valueTests: measure.valueTests,
            includeInAllUpdates: measure.includeInAllUpdates,
          };
          measureItem.extraData = {
            ...measureItem.extraData,
          };
          rowData.push(measureItem);
          delete requiredMeasures[measureId];
        }
      });
      Object.keys(requiredMeasures).forEach((measureId) => {
        rowData.push({
          id: getId(item[primaryField], measureId),
          path: [item[primaryField], measureId],
          groupId: item[primaryField],
          measureId: measureId,
          extraData: {},
          hidden: true,
        });
      });
    }
    viewDefns.forEach((measure: GridMeasureDefn) => {
      const measureId = measure.dataIndex;
      const extraData = items[item[primaryField]].extraData[measureId].extraData;
      //move data to its parent so the ag grid filter can get data
      const addData = items[item[primaryField]].extraData[measureId];
      addData[item[secondaryField]] = item[measureId];
      extraData[item[secondaryField]] = item[measureId];
      extraData[item[secondaryField] + '_editable'] = !!item['editable'];
      if (measure.dependentData && Array.isArray(measure.dependentData)) {
        if (extraData[item[secondaryField] + '_dependentData'] === undefined) {
          extraData[item[secondaryField] + '_dependentData'] = {};
        }
        measure.dependentData.forEach((index) => {
          extraData[item[secondaryField] + '_dependentData'][index] = item[index];
        });
      }
    });
  });
  return rowData;
}
export const sortingComparator = (a: any, b: any, nodeA: IRowNode, nodeB: IRowNode) => {
  // Custom comparator to sort only row groups (parent rows)
  if (nodeA.level === 0 && nodeB.level === 0) {
    return a < b ? -1 : a > b ? 1 : 0;
  }
  // For nested rows, return 0 to prevent sorting
  return 0;
};

const inputToString = (val: string | number | boolean | undefined): string => {
  switch (typeof val) {
    case 'string':
      return val;
    case 'number':
      // not using toFixed() or toPrecision() here because we really don't know what we want
      // that could be added via more inputParams
      return val.toFixed();
    case 'boolean':
      // this preserves the behvario from 'ConfigurableGrid', which treats literal true
      // as the string 'true' and false as a zero length string
      return val ? 'true' : '';
    default:
      // pray
      return 'undefined';
  }
};
const inputToNumber = (val: string | number | boolean | undefined): number => {
  switch (typeof val) {
    case 'string':
      if (!isNaN(parseFloat(val))) {
        return parseFloat(val);
      }
      throw new Error('Tried to parse a number and failed in FlowsheetGrid');
    case 'number':
      return val;
    case 'boolean':
      // looking forward to getting a bug about this in the future
      return val ? 1 : 0;
    default:
      // idk
      return 0;
  }
};
const inputToBoolean = (val: string | number | boolean | undefined): boolean => {
  // seems like a great idea
  return val && val !== '0' ? true : false;
};

export const getPayloadValueFromInputParams = (
  val: string | number | boolean | undefined,
  inputType: string
): number | string | boolean | undefined => {
  switch (inputType) {
    case 'string':
      return inputToString(val);
    case 'number':
      return inputToNumber(val);
    case 'boolean':
      return inputToBoolean(val);
    case 'undefined':
      // We don't really want to handle this case
      return val;
    default:
      // we may want to more carefully handle `null` in the future here
      // or any other types that need special handling
      return val;
  }
};

export const getValidTypedValue = (val: string | number | boolean | undefined): number | undefined => {
  if (isNil(val)) {
    return val;
  }

  if (isString(val)) {
    return parseInt(val);
  } else if (typeof val === 'boolean') {
    return val ? 1 : 0;
  } else if (isNaN(val)) {
    return 0;
  }

  return val;
};

export const getGridRowHeight = (
  data: { id?: string; groupId?: string; group?: boolean },
  rowHeight?: number,
  groupRowHeight?: number
): number => {
  return groupRowHeight && (data.id === data.groupId || data.group)
    ? groupRowHeight
    : !isNil(rowHeight)
    ? rowHeight
    : 30;
};
