import React, { useEffect, useReducer, useRef } from 'react';
import { first, isEmpty, isEqual, isNil } from 'lodash';
import { BodyScrollEvent, IRowNode } from '@ag-grid-community/core';

import { InputSuggest } from 'src/common-ui/index';
import { Suggestion } from 'src/common-ui/components/Inputs/InputSuggest/InputSuggest';

import { getValidValues } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import { getUrl } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { ValidValuesEditorProps, ValidValuesEditorState, deselectAll, selectAll } from './ValidValuesEditor';
import { ClientDataApi } from 'src/services/configuration/codecs/confdefnView';
import { EditableGridHeaderEditorBaseProps } from './TextValidationHeaderEditor';
import { Tooltip } from '@material-ui/core';
import {
  MISMATCHED_OPTIONS_TEXT,
  ROW_SELECTION_REQUIRED_TEXT,
  ROW_SELECTIONS_UPDATING_TEXT,
} from 'src/utils/Domain/Constants';
import Select from 'react-select/lib/Select';
import { ValueType } from 'react-select/lib/types';
interface ValidValuesHeaderEditorProps extends ValidValuesEditorProps, EditableGridHeaderEditorBaseProps {
  getDataConfig: (row: IRowNode, configuredApi: ClientDataApi) => ClientDataApi;
  /** Using unprocessed dataApi for header editors instead because
   * during column header generation, there are no selected rows to populate dataApi params */
  unprocessedDataConfig: ClientDataApi;
}

// Local component reducer actions
type UpdateMenuVisibility = {
  type: 'updateMenuVisibility';
  payload: {
    showMenu: boolean;
  };
};
type SetupSingleSelect = {
  type: 'setupSingleSelect';
  payload: {
    loaded: boolean;
    validValues: Suggestion[];
    selectedOption: Suggestion | undefined;
    useLabelAsValue: boolean;
  };
};
type SetupMultiSelect = {
  type: 'setupMultiSelect';
  payload: {
    loaded: boolean;
    validValues: Suggestion[];
    selectedOptions: Suggestion[];
  };
};
type UpdateSelection = {
  type: 'updateSelection';
  payload: {
    showMenu: boolean;
    selectedOption: Suggestion;
  };
};
type UpdateMultiSelection = {
  type: 'updateMultiSelection';
  payload: {
    showMenu: boolean;
    selectedOptions: Suggestion[];
  };
};
type UpdateDataConfigs = {
  type: 'updateDataConfigs';
  payload: {
    dataConfigs: ClientDataApi[];
    dataConfigParamsMatch: boolean;
  };
};

type ValidValuesHeaderEditorActions =
  | SetupSingleSelect
  | SetupMultiSelect
  | UpdateSelection
  | UpdateMultiSelection
  | UpdateDataConfigs
  | UpdateMenuVisibility;

interface ValidValuesHeaderEditorState extends ValidValuesEditorState {
  showMenu: boolean;
  dataConfigs: ClientDataApi[];
  dataConfigParamsMatch: boolean;
}

function componentReducer(
  state: ValidValuesHeaderEditorState,
  { type, payload }: ValidValuesHeaderEditorActions
): ValidValuesHeaderEditorState {
  switch (type) {
    case 'setupSingleSelect': {
      const { loaded, validValues, selectedOption, useLabelAsValue } = payload;
      return {
        ...state,
        loaded,
        selectedOption,
        useLabelAsValue,
        validValues,
      };
    }
    case 'setupMultiSelect': {
      const { loaded, validValues, selectedOptions } = payload;
      return {
        ...state,
        loaded,
        selectedOptions,
        validValues,
      };
    }
    case 'updateSelection': {
      const { selectedOption, showMenu } = payload;
      return {
        ...state,
        valueChosen: true,
        selectedOption,
        showMenu,
      };
    }
    case 'updateMultiSelection': {
      const { selectedOptions, showMenu } = payload;
      return {
        ...state,
        valueChosen: true,
        selectedOptions,
        showMenu,
      };
    }
    case 'updateDataConfigs': {
      const { dataConfigs, dataConfigParamsMatch } = payload;
      return {
        ...state,
        dataConfigs,
        dataConfigParamsMatch,
      };
    }
    case 'updateMenuVisibility': {
      const { showMenu } = payload;
      return {
        ...state,
        showMenu,
      };
    }
    default:
      return state;
  }
}
export type SingleOrMultiRef = Select<Suggestion>
/**
 * Verifies that all items selected have the same valid values options
 *
 * @param dataConfigs The currently selected grid items processed dataApis
 * @returns boolean
 */
export function selectedItemsOptionsMatch(dataConfigs: ClientDataApi[]): boolean {
  if (isEmpty(dataConfigs) || dataConfigs.length === 1) {
    return true;
  }

  const firstItem = first(dataConfigs);
  // compare first item params with every other items params, beginning with second item in array
  const allItemsParamsMatch = dataConfigs.slice(1).every((dc) => isEqual(firstItem?.params, dc.params));
  return allItemsParamsMatch;
}

export const ValidValuesHeaderEditor = (props: ValidValuesHeaderEditorProps) => {
  const {
    allowEmptyOption,
    api,
    clientHandler,
    column,
    concatOptionValues,
    dataQa,
    getDataConfig,
    ignoreCache,
    includeCurrent,
    multiSelect,
    onApplyEdit,
    options,
    getSelectedItems,
    getCellsUpdating,
    returnSelectionObject,
    unprocessedDataConfig,
    value,
  } = props;

  const inputSuggestSelectRef = useRef<SingleOrMultiRef | null>(null);
  const [state, dispatch] = useReducer(componentReducer, {
    dataConfigs: [],
    dataConfigParamsMatch: false,
    loaded: false,
    selectedOption: undefined,
    selectedOptions: [],
    showMenu: false,
    useLabelAsValue: false,
    validValues: [],
    valueChosen: false,
  });

  const {
    dataConfigs,
    dataConfigParamsMatch,
    selectedOption,
    showMenu,
    selectedOptions,
    useLabelAsValue,
    validValues,
  } = state;

  const formatSelection = (selection: Suggestion) => {
    if (!isNil(returnSelectionObject) && returnSelectionObject) {
      return selection;
    }
    return !useLabelAsValue ? selection.value : selection.label;
  };

  const formatSelections = (selections: ValueType<Suggestion>) => {
    return !isEmpty(selections)
      ? selectedOptions.map((option: Suggestion) => (!useLabelAsValue ? option.value : option.label))
      : [];
  };

  const onInputSuggestBlur = () => dispatch({ type: 'updateMenuVisibility', payload: { showMenu: false } });
  const onInputSuggestFocus = () => dispatch({ type: 'updateMenuVisibility', payload: { showMenu: true } });

  useEffect(() => {
    function bodyScrollEventListener(event: BodyScrollEvent) {
      if (event.direction === 'horizontal') {
        dispatch({ type: 'updateMenuVisibility', payload: { showMenu: false } });
      }
    }
    api.addEventListener('bodyScroll', bodyScrollEventListener);
    return () => api.removeEventListener('bodyScroll', bodyScrollEventListener);
  }, [api]);

  useEffect(() => {
    if (isEmpty(dataConfigs) || !dataConfigParamsMatch) {
      return;
    }

    // at this point we know all dataConfigs params match, safe to retrieve validValues

    const dataConfig = first(dataConfigs);
    const url = !isNil(dataConfig) ? getUrl(dataConfig) : '';
    const insertEmptyOption = multiSelect ? false : allowEmptyOption;

    getValidValues(url, insertEmptyOption, concatOptionValues, ignoreCache)
      .then((validValues: Suggestion[]) => {
        if (isNil(multiSelect) || multiSelect == false) {
          dispatch({
            type: 'setupSingleSelect',
            payload: {
              validValues,
              loaded: true,
              selectedOption: undefined,
              useLabelAsValue,
            },
          });
        } else {
          dispatch({
            type: 'setupMultiSelect',
            payload: {
              validValues,
              loaded: true,
              selectedOptions: [],
            },
          });
        }
      })
      .catch((err) => console.log(err));
  }, [
    allowEmptyOption,
    clientHandler,
    concatOptionValues,
    dataConfigs,
    dataConfigParamsMatch,
    ignoreCache,
    includeCurrent,
    multiSelect,
    options,
    value,
    useLabelAsValue,
  ]);

  useEffect(() => {
    const selectedItemsDataConfigs = getSelectedItems().map((si) => getDataConfig(si, unprocessedDataConfig));
    const dataConfigParamsMatch = selectedItemsOptionsMatch(selectedItemsDataConfigs);

    dispatch({
      type: 'updateDataConfigs',
      payload: {
        dataConfigs: selectedItemsDataConfigs,
        dataConfigParamsMatch,
      },
    });
  }, [getDataConfig, getSelectedItems, unprocessedDataConfig]);

  const onSelect = (selection: Suggestion) => {
    dispatch({
      type: 'updateSelection',
      payload: { selectedOption: selection, showMenu: false },
    });
  };

  const onMultiSelect = (selections: ValueType<Suggestion>) => {
    const newSelections: Suggestion[] = [...(selections as Suggestion[])];
    // handling "Select/Deselect All"
    if (newSelections.indexOf(selectAll) >= 0 || newSelections.indexOf(deselectAll) >= 0) {
      if (newSelections.length === validValues.length + 1) {
        dispatch({
          type: 'updateMultiSelection',
          payload: { selectedOptions: [], showMenu: false },
        });
      } else {
        dispatch({
          type: 'updateMultiSelection',
          payload: { selectedOptions: validValues, showMenu: false },
        });
      }
    } else {
      dispatch({
        type: 'updateMultiSelection',
        payload: { selectedOptions: newSelections, showMenu: false },
      });
    }
  };

  const onSingleSelection = (selection: Suggestion) => {
    onSelect(selection);
    inputSuggestSelectRef.current?.blur();
    const items = getSelectedItems();
    const formattedValue = formatSelection(selection);
    onApplyEdit(formattedValue, items, column.getColDef());
  };

  const onMultiSelection = (selections: ValueType<Suggestion>) => {
    onMultiSelect(selections);
    inputSuggestSelectRef.current?.blur();
    const items = getSelectedItems();
    const formattedValue = formatSelections(selections);
    onApplyEdit(formattedValue, items, column.getColDef());
  };

  // determine options, selection(s), and handler based on single or multi select
  const onSelectHandler = isNil(multiSelect) ? onSingleSelection : undefined;
  const onMultiSelectHandler = isNil(multiSelect) ? undefined : onMultiSelection;
  const selected = isNil(multiSelect) ? selectedOption : selectedOptions;

  let inputOptions: Suggestion[];
  if (multiSelect) {
    if (validValues.length === selectedOptions.length) {
      inputOptions = [deselectAll].concat(validValues);
    } else {
      inputOptions = [selectAll].concat(validValues);
    }
  } else {
    inputOptions = validValues;
  }

  const noSelections = isEmpty(getSelectedItems());
  const isUpdating = getCellsUpdating().length > 0;
  const mismatchedSelectionsOptions = dataConfigParamsMatch === false;
  const disabled = noSelections || mismatchedSelectionsOptions || isUpdating;
  const menuIsOpen = !disabled && showMenu;

  let title = '';
  if (noSelections) {
    title = ROW_SELECTION_REQUIRED_TEXT;
  } else if (isUpdating) {
    title = ROW_SELECTIONS_UPDATING_TEXT;
  } else if (mismatchedSelectionsOptions) {
    title = MISMATCHED_OPTIONS_TEXT;
  }

  return (
    <Tooltip title={title}>
      <InputSuggest
        selectRef={inputSuggestSelectRef}
        validValues={inputOptions}
        multiSelect={multiSelect}
        selected={selected}
        onSelect={onSelectHandler}
        onMultiSelect={onMultiSelectHandler}
        dataQa={dataQa}
        disabled={disabled}
        menuIsOpen={menuIsOpen}
        menuPortalTarget={document.body}
        onBlur={onInputSuggestBlur}
        onFocus={onInputSuggestFocus}
        placeholder=""
      />
    </Tooltip>
  );
};
