import React from 'react';
import { classes } from 'typestyle';
import { default as styles } from 'src/components/MassEdit/MassEdit.styles';
import { default as criteriaStyles } from 'src/pages/AssortmentStrategy/TargetSetting/Criteria/Criteria.style';
import { default as ReactSelect } from 'react-select';
import WeekRangePicker from 'src/common-ui/components/WeekRange/WeekRangePicker';
import { DaysRangeListResponse } from 'src/types/Scope';
import { MassEditMultiSelect } from './MultiSelect';
import { WeekRange } from 'src/common-ui/components/WeekRange/WeekRangePicker.interface';
import { getWeekListFromRange, WeekNumberList } from '../AssortmentScopeSelector/AssortmentScopeSelector.utils';
import { isEmpty, isNil } from 'lodash';
import DividedColumnDetailsDropdown from 'src/pages/AssortmentBuild/StyleEdit/DividedColumnDetailsSection/DividedColumnDetailsDropdown';
import { simpleByField } from 'src/utils/Pivot/Sort';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ClientDataApi } from 'src/services/configuration/codecs/confdefnView';
import { arrowlessNumberInputStyle } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/IntegerEditor/IntegerEditor.styles';
import TemplateModalWithButton from '../TemplateModalWithButton/TemplateModalWithButton';
import { Button, FormControl, FormLabel, Radio, RadioGroup, Tooltip } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core';
import { muiTheme } from 'src/utils/Style/Theme';
import { Option } from '../Subheader/Subheader';
import { ExtraModifier } from 'src/services/configuration/codecs/viewdefns/viewdefn';

export interface MassEditSelectItemData {
  value: string;
  label: string;
}

interface MassEditConfigItem {
  title?: string;
  dataIndex: string;
  editor?: string;
  renderer?: string;
  text?: string;
  dataApi?: ClientDataApi;
  subtitle?: string;
  calculation?: string;
  options?: Option[];
  extraModifiers?: ExtraModifier[];
}

interface PrimaryConfigItem {
  title: string;
  modifierTypes: MassEditConfigItem[];
}

export interface MassEditConfig {
  view?: [PrimaryConfigItem, MassEditConfigItem];
  views?: [PrimaryConfigItem, MassEditConfigItem];
  hideEmpty?: boolean;
}

/**
 * @deprecated
 * Please use MassEdit props associated with MassEditv2.tsx
 */
export interface MassEditProps {
  dataLoading?: boolean;
  config?: MassEditConfig;
  allowFrom: string;
  allowTo: string;
  scopeStart: string;
  rangeList: DaysRangeListResponse;
  title: string;
  editableItems?: MassEditSelectItemData[];
  handleCancel?: () => void;
  handleSubmit: (submissionData: MassEditState) => void;
}

export interface MassEditState {
  selectedModifier: string | null;
  selectedEditor: string | null;
  selectedParser: string | null;
  selectedItems: MassEditSelectItemData[];
  selectedWeekList: WeekNumberList | null;
  selectedSubtitle: string | null;
  modifierValue: string | number | null;
  userSetNull: boolean;
  editableItems?: MassEditSelectItemData[];
  extraModifierValues: Record<string, string | number | (string | number)[] | null>;
}

export interface MassEditSubmissionData {
  selectedModifier: string | null;
  selectedItems: MassEditSelectItemData[];
  selectedWeekList: WeekNumberList | null;
  modifierValue: string | number | null;
  extraModifierValues: Record<string, string | number | (string | number)[] | null>;
}

type ReactSelectEvent = any;

const Divider = () => {
  return (
    <div className={classes(criteriaStyles.triangleBox, styles.dividerItem)}>
      <div className={styles.divider} />
    </div>
  );
};

const initialState = {
  selectedEditor: null,
  selectedModifier: null,
  selectedItems: [],
  selectedWeekList: null,
  modifierValue: null,
  userSetNull: false,
  selectedParser: null,
  selectedSubtitle: null,
  extraModifierValues: {}, // Start empty
};

export class MassEdit extends React.Component<MassEditProps, MassEditState> {
  state: MassEditState;

  constructor(props: MassEditProps) {
    super(props);
    this.state = initialState;
  }

  selectionsValid() {
    const { selectedEditor, selectedWeekList, selectedItems, modifierValue, userSetNull } = this.state;
    const hideEmpty = this.props.config?.hideEmpty;
    const emptyInputAllowed =
      (!hideEmpty && isNil(modifierValue) && userSetNull) || (!isNil(modifierValue) && modifierValue !== 0);

    const isValid = !isNil(selectedWeekList) && !isEmpty(selectedItems) && emptyInputAllowed;

    if (selectedEditor === 'numberInput') {
      // numberInputs can set values to null via checkbox
      return isValid;
    }

    return isValid && !isNil(modifierValue);
  }

  renderRangeAndModifierSelectors(modifierConfig: PrimaryConfigItem) {
    const { allowFrom, allowTo, rangeList, scopeStart } = this.props;
    const { title, modifierTypes } = modifierConfig;
    const options = modifierTypes.map((modifier) => {
      const { dataIndex, text, editor, renderer, subtitle } = modifier;
      return {
        value: dataIndex,
        label: text,
        editor: editor,
        parser: renderer,
        subtitle: subtitle,
      };
    });

    const eventDropdown = (
      <div className={styles.sectionContentItem}>
        <div className={styles.subSectionTitle} data-qa={'mass-edit-event-dropdown'}>
          Select Event Type:
        </div>
        <ReactSelect options={options} className={'mass-edit-event-dropdown'} onChange={this.handleModifierSelection} />
      </div>
    );
    const subtitle = this.state.selectedSubtitle || 'Specify Value:';

    return (
      <section>
        <div className={styles.sectionTitle}>{title}</div>
        <div className={styles.sectionContent}>
          <div className={styles.sectionContentItem}>
            <div className={styles.subSectionTitle}>Select Week(s):</div>
            <WeekRangePicker
              autoSelect={true}
              allowFrom={allowFrom}
              allowTo={allowTo}
              dateToNameMap={rangeList}
              selectionOptions={[{ label: 'Start' }, { label: 'End' }]}
              onNewWeeksSelected={this.handleWeekRangeSelection}
              startMonth={scopeStart}
            />
          </div>
          {modifierTypes.length > 1 ? eventDropdown : undefined}
          <div className={classes(styles.sectionContentItem, styles.modifierInput)}>
            {!isNil(this.state.selectedEditor) && <div className={styles.subSectionTitle}>{subtitle}</div>}
            {this.getModifier(modifierConfig)}
          </div>
        </div>
      </section>
    );
  }

  handleExtraModifierChange = (dataIndex: string, value: string | number | null) => {
    this.setState((prevState) => ({
      extraModifierValues: {
        ...prevState.extraModifierValues,
        [dataIndex]: value,
      },
    }));
  };

  getExtraModifiers(filter: ExtraModifier) {
    const currentVal = this.state.extraModifierValues[filter.dataIndex] || filter.options?.[0]?.value || '';

    switch (filter.editor) {
      case 'radiogroup':
        return (
          <FormControl style={{ margin: '1rem 0' }} key={filter.dataIndex}>
            <FormLabel style={{ fontSize: '14px', fontWeight: 'bold' }}>{filter.text}</FormLabel>
            <RadioGroup
              value={currentVal}
              onChange={(event) => this.handleExtraModifierChange(filter.dataIndex, event.target.value)}
              style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}
            >
              {filter.options.map((opt) => (
                <FormControlLabel
                  key={opt.value}
                  value={String(opt.value)}
                  control={<Radio style={{ transform: 'scale(0.9)' }} />}
                  label={<span style={{ fontSize: '12px' }}>{opt.label}</span>}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case 'checkboxgroup': {
        // Convert the current value to an array of selected values.
        const currentVal = this.state.extraModifierValues[filter.dataIndex];
        const currentArray: (string | number)[] = Array.isArray(currentVal)
          ? currentVal
          : isNil(currentVal)
          ? []
          : [currentVal];

        const handleCheckboxChange = (optValue: string | number, checked: boolean) => {
          this.setState((prevState) => {
            const prevVal = prevState.extraModifierValues[filter.dataIndex];
            const prevArray: (string | number)[] = Array.isArray(prevVal) ? prevVal : isNil(prevVal) ? [] : [prevVal];

            let newValues = [...prevArray];

            if (checked) {
              // Add the selected value if not already present
              if (!newValues.includes(optValue)) {
                newValues.push(optValue);
              }
            } else {
              // Remove the deselected value if present
              newValues = newValues.filter((val) => val !== optValue);
            }

            const totalOptions = filter.options.length;
            const selectedCount = newValues.length;

            const updatedExtraModifiers = { ...prevState.extraModifierValues };

            // If zero or all selected, remove the property from the state
            if (selectedCount === 0 || selectedCount === totalOptions) {
              delete updatedExtraModifiers[filter.dataIndex];
            } else {
              updatedExtraModifiers[filter.dataIndex] = newValues;
            }

            return { extraModifierValues: updatedExtraModifiers };
          });
        };

        // Filter currentArray to ensure only valid options remain
        const selectedValues = currentArray.filter((val) => filter.options.some((opt) => opt.value === val));

        return (
          <FormControl style={{ margin: '1rem 0' }} key={filter.dataIndex}>
            <FormLabel style={{ fontSize: '14px', fontWeight: 'bold' }}>{filter.text}</FormLabel>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
              {filter.options.map((opt) => {
                const isChecked = selectedValues.includes(opt.value);
                return (
                  <FormControlLabel
                    key={opt.value}
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={(event) => handleCheckboxChange(opt.value, event.target.checked)}
                      />
                    }
                    label={<span style={{ fontSize: '12px' }}>{opt.label}</span>}
                  />
                );
              })}
            </div>
          </FormControl>
        );
      }

      default:
        return null;
    }
  }

  getModifier(modifierConfig: PrimaryConfigItem) {
    const { selectedEditor } = this.state;
    const hideEmpty = this.props.config ? this.props.config.hideEmpty : false;

    const selectedModifier = modifierConfig.modifierTypes.find((m) => m.dataIndex === this.state.selectedModifier);
    if (!selectedModifier) return null;

    switch (selectedEditor) {
      case 'validValuesEditor':
        const dataApi = selectedModifier.dataApi;
        return (
          <>
            {dataApi && (
              <DividedColumnDetailsDropdown
                key={''}
                dataQa={'mass-edit-select-modifier'}
                value={''}
                styleId={''}
                asCsv={false}
                multiSelect={false}
                dataApi={dataApi}
                handleDropdownChange={this.handleModifierDropdownChange}
              />
            )}
            {/* If this selectedModifier has extraModifiers, render below */}
            {selectedModifier.extraModifiers &&
              selectedModifier.extraModifiers.map((filter) => this.getExtraModifiers(filter))}
          </>
        );
      case 'numberInput':
        const { userSetNull } = this.state;
        const empty = !hideEmpty && (
          <div>
            <FormControlLabel
              control={<Checkbox checked={userSetNull} onChange={this.toggleUserSetNull} />}
              label="Set value to 'empty'"
            />
          </div>
        );
        return (
          <>
            <input
              type="number"
              disabled={userSetNull}
              onChange={this.handleModifierValueChange}
              data-qa={'mass-edit-input-number'}
              className={classes(styles.massEditInput, arrowlessNumberInputStyle)}
            />
            {empty}
            {/* If extraModifiers exist for this modifier, render below */}
            {selectedModifier.extraModifiers &&
              selectedModifier.extraModifiers.map((filter) => this.getExtraModifiers(filter))}
          </>
        );

      default:
        return null;
    }
  }

  renderItemsSelector(itemsConfig: MassEditConfigItem) {
    const { title } = itemsConfig;
    const editableItems = this.props.editableItems;
    if (isNil(editableItems)) {
      return <div />;
    }
    let limit;
    if (this.state.selectedItems.length > 700) {
      limit = <span style={{ color: '#f6413c', fontSize: 10 }}>Mass Edit is Limited to 700 or less items.</span>;
    }
    const sortedItems = simpleByField([...editableItems], 'label', 'asc'); // sort data by label value

    return (
      <section className={styles.multiSelectSection}>
        <div className={styles.sectionTitle}>{title}</div>
        {limit}
        <div>
          <MassEditMultiSelect items={sortedItems} handleItemSelection={this.handleItemSelection} />
        </div>
      </section>
    );
  }

  renderSubmitButton() {
    let button;

    if (!this.selectionsValid() || this.state.selectedItems.length > 700) {
      const tip =
        this.state.selectedItems.length > 700
          ? 'Please select 700 items or less.'
          : 'All section data is required to submit.';
      button = (
        <Tooltip title={tip}>
          <div>
            <button className={styles.actionButton} onClick={this.handleSubmit} disabled={true}>
              <i className="far fa-check" />
              SUBMIT
            </button>
          </div>
        </Tooltip>
      );
    } else {
      button = (
        <button className={styles.actionButton} onClick={this.handleSubmit}>
          <i className="far fa-check" />
          SUBMIT
        </button>
      );
    }

    return button;
  }

  handleModifierSelection = (event: ReactSelectEvent) => {
    this.setState({
      selectedModifier: event.value,
      selectedEditor: event.editor,
      selectedParser: event.parser,
      selectedSubtitle: event.subtitle,
    });
  };

  handleWeekRangeSelection = (range: WeekRange) => {
    const weekNumberList = getWeekListFromRange(range, this.props.rangeList);
    this.setState({
      selectedWeekList: weekNumberList,
    });
  };

  handleModifierValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { selectedParser } = this.state;

    let value = +event.target.value; // convert string to number
    if (selectedParser === 'percent') {
      value = value / 100;
    }

    this.setState({
      modifierValue: value,
      userSetNull: false,
    });
  };

  toggleUserSetNull = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    this.setState({
      modifierValue: null,
      userSetNull: checked,
    });
  };

  handleModifierDropdownChange = (selection: MassEditSelectItemData) => {
    this.setState({
      modifierValue: selection.value,
    });
  };

  handleItemSelection = (selections: MassEditSelectItemData[]) => {
    this.setState({
      selectedItems: selections,
    });
  };

  handleSubmit = () => {
    if (!this.selectionsValid()) {
      return;
    }

    this.props.handleSubmit(this.state);
    this.setState({
      ...initialState,
    });
  };

  resetState = () => {
    this.setState(initialState);
  };

  static getConfigViewItems(config: MassEditConfig | undefined): [PrimaryConfigItem, MassEditConfigItem] {
    let viewConfigs: [PrimaryConfigItem, MassEditConfigItem] = ([] as unknown) as [
      PrimaryConfigItem,
      MassEditConfigItem
    ];

    if (!isNil(config)) {
      if (!isNil(config.view)) {
        viewConfigs = config.view;
      } else if (!isNil(config.views)) {
        viewConfigs = config.views;
      }
    }

    return viewConfigs;
  }

  componentDidUpdate() {
    const { config } = this.props;
    const viewConfigs = MassEdit.getConfigViewItems(config);
    const modifierConfig = viewConfigs[0];
    const modifierTypes = modifierConfig?.modifierTypes || [];
    if (modifierTypes.length == 1 && !this.state.selectedModifier) {
      const editor = modifierTypes[0].editor;
      const renderer = modifierTypes[0].renderer;
      const subtitle = modifierTypes[0].subtitle;
      if (editor && renderer && subtitle) {
        this.setState({
          selectedModifier: modifierTypes[0].dataIndex,
          selectedEditor: editor,
          selectedParser: renderer,
          selectedSubtitle: subtitle,
        });
      }
    }
  }

  render() {
    const { config, editableItems } = this.props;
    const configViewItems = MassEdit.getConfigViewItems(config);
    let mainContent;
    if (isNil(configViewItems) || isNil(editableItems)) {
      mainContent = <div />;
    } else {
      const [modifierConfig, valuesConfig] = configViewItems;

      mainContent = (
        <div className={styles.contentContainer}>
          <div className={styles.contentItemsContainer}>
            <article className={styles.contentItem}>{this.renderRangeAndModifierSelectors(modifierConfig)}</article>
            <Divider />
            <article className={styles.contentItem}>{this.renderItemsSelector(valuesConfig)}</article>
          </div>
        </div>
      );
    }

    const buttonComponent = (
      <div className={styles.actionButtonGroup}>
        <Button color={'secondary'} className={styles.actionButtonButton} disabled={this.props.dataLoading}>
          <i className={classes('fa fa-object-group', styles.actionButtonIcon)} />
          <span>Mass Edit</span>
        </Button>
      </div>
    );

    return (
      <MuiThemeProvider theme={muiTheme}>
        <TemplateModalWithButton
          buttonComponent={
            this.props.dataLoading ? (
              <Tooltip title={'Data Loading...'} placement="bottom-end">
                {buttonComponent}
              </Tooltip>
            ) : (
              buttonComponent
            )
          }
          title={this.props.title}
          showSubmit={true}
          onOpen={() => undefined}
          onCancel={() => this.resetState()}
          customSubmitComponent={this.renderSubmitButton()}
        >
          {mainContent}
        </TemplateModalWithButton>
      </MuiThemeProvider>
    );
  }
}
