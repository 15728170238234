import ColorSwatch from 'src/common-ui/components/ColorSwatch/ColorSwatch';
import { concat, noop, get, isArray, isNil, isEmpty } from 'lodash';
import { cloneDeep } from 'lodash/fp';
import React from 'react';

import { Button, createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';

import { CartItem, ColorSwatchItem } from '../AssortmentCart.types';
import { default as ReactSelect } from 'react-select';

import { style, classes } from 'typestyle';

import { getSwatchUrl } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import styles, { swatchStyles } from './ColorsEdit.styles';
import { watermarkImageAsDataURI } from 'src/utils/Images/WatermarkImage';
import { resolvePath } from 'src/cdn';
import { ISASSORTMENT, CCCOLORID, IMG_URI } from 'src/utils/Domain/Constants';
import { getStyleColorsAttr } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.client';
import { Props as ReactSelectProps } from 'react-select/lib/Select';
import noImagePath from 'src/common-ui/images/noimage.jpg';
const noImage = resolvePath(noImagePath);

type Props = ValueProps & FunctionProps;
type ValueProps = {
  item: CartItem;
  colors: string[];
  title: string;
  placeholderSrc: string;
  portalRef?: React.RefObject<HTMLElement>;
  canAddNewStyles: boolean;
};
type FunctionProps = {
  getStylePlaceholder: (styleId: string) => string;
  changeSwatches?: (swatches: ColorSwatchItem[]) => void;
  closeModal: () => void;
};
type SelectedOption = {
  label: string;
  value: string;
  isExisting?: boolean;
};
type State = {
  selectedColorId: string | null;
  newSwatches: ColorSwatchItem[];
  existingSwatches: ColorSwatchItem[];
  assortmentSwatches: ColorSwatchItem[];
  selectedNewColor: SelectedOption | null;
  placeholderCoveredSrc: string;
  colorsInProduct: ColorSwatchItem[];
};
const muistyles = (theme: Theme) => {
  return createStyles({
    paper: {
      width: theme.spacing(50),
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
    },
  });
};

interface StyleIProps extends WithStyles<typeof muistyles>, Props {}

class ColorsEdit extends React.Component<StyleIProps, State> {
  state: State;

  constructor(props: StyleIProps) {
    super(props);

    this.state = {
      selectedColorId: null,
      newSwatches: [],
      existingSwatches: [],
      assortmentSwatches: [],
      selectedNewColor: null,
      placeholderCoveredSrc: props.placeholderSrc || '',
      colorsInProduct: [],
    };
  }

  setSwatchStateFromProps = () => {
    const asstSw = this.props.item.currentAssortmentChildren
      ? this.props.item.currentAssortmentChildren.filter((_i) => {
          return this.props.item.type === 'existing';
        })
      : [];

    const existSw =
      this.props.item.type === 'existing' || this.props.item.type === 'similar' ? this.props.item.currentChildren : [];

    this.setState({
      selectedColorId: get(this.props.item.swatches, '[0]["attribute:cccolor:id"]', null),
      newSwatches: this.props.item.swatches,
      assortmentSwatches: cloneDeep(asstSw),
      existingSwatches: cloneDeep(existSw),
    });
  };

  fetchAllSwatches = async () => {
    const everyProductInStyle = await getStyleColorsAttr(this.props.item.id, true);
    const colorsInProduct = everyProductInStyle.map((prod) => {
      return {
        type: 'existing',
        swatchPath: '',
        colorId: prod['attribute:cccolor:id'],
        ...prod,
      };
    });
    this.setState({
      colorsInProduct: colorsInProduct,
    });
  };

  async componentDidMount() {
    this.setSwatchStateFromProps();
    await this.createPlaceholder();
    this.fetchAllSwatches();
    if (this.state.newSwatches.length <= 0) {
      this.addColor();
    }
  }

  async createPlaceholder() {
    const newImage = await watermarkImageAsDataURI(this.props.placeholderSrc).catch(() =>
      watermarkImageAsDataURI(noImage)
    );
    this.setState({
      placeholderCoveredSrc: newImage || noImage,
    });
  }

  componentDidUpdate(oldProps: StyleIProps) {
    if (oldProps.item.id !== this.props.item.id && oldProps.item._id !== this.props.item._id) {
      this.setSwatchStateFromProps();
    }
    if (oldProps.placeholderSrc !== this.props.placeholderSrc) {
      this.createPlaceholder();
    }
  }

  getColors = () => {
    return this.state.newSwatches;
  };

  getSelectedColor = () => {
    const selectedColor: ColorSwatchItem | undefined = this.getColors().find(
      (col) => col.colorId === this.state.selectedColorId
    );
    return selectedColor;
  };
  getSelectedInd = () => {
    const selectedColor: number | undefined = this.getColors().findIndex(
      (col) => col.colorId === this.state.selectedColorId
    );
    return selectedColor || 0;
  };
  buildSwatches = () => {
    const swatches = this.getColors().map((swatch) => {
      return {
        swatchPath: '',
        id: swatch.colorId || '',
        colorId: swatch.colorId,
        selected: this.getSelectedColor() === swatch,
        noImageUrl: '',
      };
    });
    return (
      <ColorSwatch
        noImageUrl={''}
        className={swatchStyles}
        style={{ display: 'inline-block', overflowX: 'auto', marginTop: 5, maxWidth: 200 }}
        onChangeColor={(id: string, _position: number) => {
          this.setState({
            selectedColorId: id,
          });
        }}
        colors={swatches}
        selected={this.getSelectedInd()}
        getSwatchUrl={getSwatchUrl}
        renderTooltip={true}
      />
    );
  };
  buildExistingSwatches = () => {
    const swatches = this.state.assortmentSwatches.map((swatch) => {
      return {
        swatchPath: '',
        id: swatch.colorId || '',
        colorId: swatch.colorId,
        selected: this.getSelectedColor() === swatch,
        noImageUrl: '',
      };
    });
    return (
      <ColorSwatch
        noImageUrl={''}
        className={swatchStyles}
        style={{ display: 'inline-block', overflowX: 'auto' }}
        onChangeColor={noop}
        colors={swatches}
        selected={-1}
        getSwatchUrl={getSwatchUrl}
        renderTooltip={true}
      />
    );
  };
  getExistingChild = (colorId: string) => {
    return this.state.colorsInProduct.find((child) => {
      return child[CCCOLORID] === colorId;
    });
  };
  inExistingChildren = (colorId: string) => {
    return this.getExistingChild(colorId) != null;
  };
  addColor = async () => {
    const selectedNewColor = this.state.selectedNewColor;
    if (selectedNewColor == null) {
      return noop();
    }
    const newColorValue = selectedNewColor.value;
    const passedStyle = this.props.item;
    const colorExists = this.getExistingChild(newColorValue);
    const copiedColor: ColorSwatchItem =
      this.getExistingChild(newColorValue) || this.getSelectedColor() || this.props.item.currentChildren[0];
    let imageSrc: string | null = null;
    if (colorExists) {
      if (this.props.item.type === 'existing') {
        imageSrc = copiedColor[IMG_URI];
      } else {
        imageSrc = await watermarkImageAsDataURI(copiedColor[IMG_URI]).catch(() => watermarkImageAsDataURI(noImage));
      }
    }
    const newItem = {
      ...copiedColor,
      name: `${passedStyle.name}-${newColorValue.split(' ')[0]}`,
      description: `${passedStyle.description} ${newColorValue}`,
      colorId: selectedNewColor.value,
      [ISASSORTMENT]: 'false',
      [CCCOLORID]: newColorValue,
      ['attribute:cccolorid:id']: newColorValue.split(' ')[0],
      'attribute:img:id': imageSrc || this.state.placeholderCoveredSrc,
      isWatermarked: true,
      type: colorExists && this.props.item.type === 'existing' ? 'existing' : 'similar',
      swatchPath: getSwatchUrl(newColorValue),
    };

    const newItems: ColorSwatchItem[] = concat(this.getColors(), [newItem]);

    this.setState({
      newSwatches: newItems,
      selectedNewColor: null,
      selectedColorId: newColorValue,
    });
  };
  removeColor = () => {
    const selectedColor = this.getSelectedColor();
    const newItems = this.getColors().filter((swatch) => swatch !== selectedColor);
    this.setState({
      newSwatches: newItems,
      selectedColorId: get(newItems, '[0]["attribute:cccolor:id"]', null),
    });
  };

  onClickUpdate = () => {
    if (this.props.changeSwatches && !isEmpty(this.state.newSwatches)) {
      this.props.changeSwatches(this.state.newSwatches);
    }
  };

  renderAddColorList = () => {
    const existColorInProduct = this.state.colorsInProduct.filter((item) =>
      this.state.existingSwatches.find((item2) => item2[CCCOLORID] === item[CCCOLORID])
    );
    const options = this.props.colors
      .filter((color) => {
        return (
          this.state.newSwatches.map((s) => s.colorId).indexOf(color) < 0 &&
          this.state.assortmentSwatches.map((s) => s.colorId).indexOf(color) < 0
        );
      })
      .filter((color) => {
        if (this.props.canAddNewStyles === false) {
          return this.inExistingChildren(color);
        } else {
          return true;
        }
      })
      .filter((color) =>
        this.props.canAddNewStyles ? color : existColorInProduct.find((item) => item[CCCOLORID] === color)
      )
      .map((color) => {
        const displayName: string = color;
        const isExisting = this.inExistingChildren(color);
        return {
          value: color,
          label: displayName,
          isExisting,
        };
      });
    const selectComponentOptions: ReactSelectProps = {
      className: style({ fontSize: '0.9rem', width: 160 }),
      styles: {
        menuPortal: (props) => {
          return {
            ...props,
            zIndex: 1301,
          };
        },
        menu: (props) => {
          return {
            ...props,
            zIndex: 1302,
          };
        },
        option: (props, state) => {
          return {
            ...props,
            color: state.data.isExisting ? 'blue' : 'black',
          };
        },
      },
      value: this.state.selectedNewColor,
      options,
      onChange: (event) => {
        if (event && !isArray(event)) {
          this.setState({
            selectedNewColor: event,
          });
        }
      },
      menuPortalTarget: document.body,
    };
    const selectComponent = <ReactSelect {...selectComponentOptions} />;
    return (
      <div style={{ display: 'inline-block' }} data-qa="add-color-dropdown">
        {selectComponent}
      </div>
    );
  };

  closeModal() {
    this.props.closeModal();
  }

  render() {
    const { placeholderSrc } = this.props;
    const selectedColor = this.getSelectedColor();
    // newly added swatch colors need to display placeholder image
    const selectedColorImgSrc = !isNil(selectedColor)
      ? selectedColor['attribute:img:id']
        ? selectedColor['attribute:img:id']
        : noImage
      : placeholderSrc;

    const selectedItemInfo = (
      <React.Fragment>
        <section style={{ display: 'flex' }}>
          <div
            style={{
              alignSelf: 'center',
              backgroundSize: 'cover',
              width: 150,
              display: 'inline-block',
            }}
          >
            <img style={{ width: '100%' }} src={selectedColorImgSrc} data-qa="edit-color-modal-image" />
            <div style={{ fontSize: '0.7rem' }}>In Assortment</div>
            {this.props.item.type === 'existing' ? this.buildExistingSwatches() : null}
          </div>
          <div
            style={{
              marginLeft: 30,
              marginTop: 3,
              display: 'flex',
              flexDirection: 'column',
              width: 300,
            }}
          >
            <div
              style={{
                width: '100%',
                marginTop: 40,
              }}
            >
              <div
                style={{ padding: '10px 0', fontSize: '0.9rem', display: 'block' }}
                data-qa="EditColorsSelectedSwatchText"
              >
                {`COLOR: ${selectedColor ? selectedColor.colorId : ''}`}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {this.buildSwatches()}
                <Button
                  disabled={isNil(selectedColor) || this.getColors().length < 2}
                  className={styles.textButton}
                  onClick={this.removeColor}
                  style={{
                    fontSize: '0.8rem',
                    margin: '0 5px',
                  }}
                  data-qa="remove-color-btn"
                >
                  REMOVE
                </Button>
              </div>
            </div>
            <div
              style={{
                marginTop: 10,
                width: '100%',
                fontSize: '0.9rem',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {this.renderAddColorList()}
              <Button
                disabled={!this.state.selectedNewColor}
                className={styles.textButton}
                onClick={this.addColor}
                style={{
                  fontSize: '0.8rem',
                  margin: '0 5px',
                }}
                data-qa="add-color-btn"
              >
                ADD
              </Button>
            </div>
          </div>
        </section>
      </React.Fragment>
    );

    return (
      <div className={styles.container} data-qa="edit-color-modal-container">
        <div className={styles.titleContainer}>
          <span className={styles.buttonCancel} onClick={() => this.closeModal()}>
            {'x'}
          </span>
        </div>
        <div className={styles.subtitleContainer}>
          <span className={styles.titleText} data-qa="edit-color-modal-title">
            {this.props.title}
          </span>
        </div>
        <div className={styles.bodyContainer}>{selectedItemInfo}</div>
        <div className={styles.footerContainer}>
          <div
            className={classes(styles.s5button, isNil(selectedColor) ? styles.getDisabledTextButtonStyles() : '')}
            onClick={() => this.onClickUpdate()}
            data-qa="update-style-btn"
          >
            <span>UPDATE STYLES</span>
          </div>
          <div className={styles.s5buttonCancel} onClick={() => this.closeModal()} data-qa="cancel-edit-btn">
            <span>CANCEL</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(muistyles)(ColorsEdit);
